/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { BehaviorSubject, pipe, switchMap, tap } from 'rxjs';
import {
  AuthBackendService,
  AuthCookieService,
  BrowserInfoService,
} from '@sybl/feature-auth-data-access';
import { SyblAgencyState } from './sybl-agency-store';
import { ISessionResponse } from '@sybl/feature-auth-models';
import {
  IWebSocketMessage,
  WebSocketMessage,
} from '@sybl/feature-websocket-models';
import {
  DocumentStatusEnum,
  IDocumentSaveError,
} from '@sybl/feature-documents-models';
import { UUID } from '@sybl/common-models';
import { ISyblChat, SyblChat } from '@sybl/feature-sybl-models';
import { SyblAccountManagementHttpService } from '../services';

export function withSyblAgencyMethods() {
  return signalStoreFeature(
    { state: type<SyblAgencyState>() },
    withMethods(
      (
        state,
        syblAccountManagementHttpService = inject(
          SyblAccountManagementHttpService
        )
      ) => ({
        async getAgencyWithUser(agency_id: string, user_id: string) {
          return await syblAccountManagementHttpService
            .getSyblAgencyWithUser(agency_id, user_id)
            .subscribe((response) => {
              const agency = response.agency;

              patchState(state, {
                agency_id: agency.agency_id,
                uuid: agency.uuid,
                kill_bill_id: agency.kill_bill_id,
                earlyAdopter: agency.earlyAdopter,
                agency_name: agency.agency_name,
                email: agency.email,
                tenant: agency.tenant,
                purchased_seats: agency.purchased_seats,
                active_seats: agency.active_seats,
                inactive_seats: agency.inactive_seats,
                employeeCount: agency.employeeCount,
                yearlyRevenue: agency.yearlyRevenue,
                billingPeriod: agency.billingPeriod,
                productCategory: agency.productCategory,
                phaseType: agency.phaseType,
                subscriptionState: agency.subscriptionState,
                sourceType: agency.sourceType,
                currency: agency.currency,
                licenseType: agency.licenseType,
                address1: agency.address1,
                address2: agency.address2,
                city: agency.city,
                country: agency.country,
                state: agency.state,
                longitude: agency.longitude,
                latitude: agency.latitude,
                gpsLocation: agency.gpsLocation,
                phone: agency.phone,
                postalCode: agency.postalCode,
                shippingAddress1: agency.shippingAddress1,
                shippingAddress2: agency.shippingAddress2,
                shippingCity: agency.shippingCity,
                shippingState: agency.shippingState,
                shippingCountry: agency.shippingCountry,
                shippingLongitude: agency.shippingLongitude,
                shippingLatitude: agency.shippingLatitude,
                shippingGpsLocation: agency.shippingGpsLocation,
                shippingPhone: agency.shippingPhone,
                shippingPostalCode: agency.shippingPostalCode,
                date_time: agency.date_time,
                dateModified: agency.dateModified,
                syblUsers: agency.syblUsers,
                naicsCodes: agency.naicsCodes,
                rnCodes: agency.rnCodes,
                licensedStates: agency.licensedStates,
                topCarriers: agency.topCarriers,
                personalLines: agency.personalLines,
              });

              return response;
            });
        },
        getAgencyUser(user_id) {
          const syblUsers = state.syblUsers();
          const index = syblUsers.findIndex((entry) => entry.user_id === user_id);

          return syblUsers[index];
        },
        async getSyblAgencyForToken(token) {
           return await syblAccountManagementHttpService
            .getSyblAgencyForToken(token)
            .subscribe((response) => {
              if (response.agency !== null && response.agency !== undefined) {
                patchState(state, {
                  active_seats: response.agency.active_seats,
                  address1: response.agency.address1,
                  address2: response.agency.address2,
                  tenant: response.agency.tenant,
                  earlyAdopter: response.agency.earlyAdopter,
                  agency_id: response.agency.agency_id,
                  postalCode: response.agency.postalCode,
                  email: response.agency.email,
                  agency_name: response.agency.agency_name,
                  shippingGpsLocation: response.agency.shippingGpsLocation,
                  city: response.agency.city,
                  currency: response.agency.currency,
                  date_time: response.agency.date_time,
                  dateModified: response.agency.dateModified,
                  gpsLocation: response.agency.gpsLocation,
                  licenseType: response.agency.licenseType,
                  purchased_seats: response.agency.purchased_seats,
                  state: response.agency.state,
                  syblUsers: response.agency.syblUsers,
                  user: response.agency.user,
                  individualAccount: response.individualAccount
                });
              }
              return response;
            });
        },
        agency() {
          return {
            agency_id: state.agency_id(),
            uuid: state.uuid(),
            tenant: state.tenant(),
            kill_bill_id: state.kill_bill_id(),
            email: state.email(),
            earlyAdopter: state.earlyAdopter(),
            agency_name: state.agency_name(),
            purchased_seats: state.purchased_seats(),
            active_seats: state.active_seats(),
            inactive_seats: state.inactive_seats(),
            employeeCount: state.employeeCount(),
            yearlyRevenue: state.yearlyRevenue(),
            billingPeriod: state.billingPeriod(),
            productCategory: state.productCategory(),
            phaseType: state.phaseType(),
            subscriptionState: state.subscriptionState(),
            sourceType: state.sourceType(),
            currency: state.currency(),
            licenseType: state.licenseType(),
            address1: state.address1(),
            address2: state.address2(),
            city: state.city(),
            country: state.country(),
            state: state.state(),
            longitude: state.longitude(),
            latitude: state.latitude(),
            gpsLocation: state.gpsLocation(),
            phone: state.phone(),
            postalCode: state.postalCode(),
            shippingAddress1: state.shippingAddress1(),
            shippingAddress2: state.shippingAddress2(),
            shippingCity: state.shippingCity(),
            shippingState: state.shippingState(),
            shippingCountry: state.shippingCountry(),
            shippingLongitude: state.shippingLongitude(),
            shippingLatitude: state.shippingLatitude(),
            shippingGpsLocation: state.shippingGpsLocation(),
            shippingPhone: state.shippingPhone(),
            shippingPostalCode: state.shippingPostalCode(),
            date_time: state.date_time(),
            dateModified: state.dateModified(),
            syblUsers: state.syblUsers(),
            naicsCodes: state.naicsCodes(),
            rnCodes: state.rnCodes(),
            licensedStates: state.licensedStates(),
            topCarriers: state.topCarriers(),
            personalLines: state.personalLines(),
          };
        },
        setAgency(agency, user) { 
          patchState(state, {
            agency_id: agency.agency_id,
            kill_bill_id: agency.kill_bill_id,
            tenant: agency.tenant,
            uuid: agency.uuid,
            email: agency.email,
            earlyAdopter: agency.earlyAdopter,
            agency_name: agency.agency_name,
            purchased_seats: agency.purchased_seats,
            active_seats: agency.active_seats,
            inactive_seats: agency.inactive_seats,
            employeeCount: agency.employeeCount,
            yearlyRevenue: agency.yearlyRevenue,
            billingPeriod: agency.billingPeriod,
            productCategory: agency.productCategory,
            phaseType: agency.phaseType,
            subscriptionState: agency.subscriptionState,
            sourceType: agency.sourceType,
            currency: agency.currency,
            licenseType: agency.licenseType,
            address1: agency.address1,
            address2: agency.address2,
            city: agency.city,
            country: agency.country,
            state: agency.state,
            longitude: agency.longitude,
            latitude: agency.latitude,
            gpsLocation: agency.gpsLocation,
            phone: agency.phone,
            postalCode: agency.postalCode,
            shippingAddress1: agency.shippingAddress1,
            shippingAddress2: agency.shippingAddress2,
            shippingCity: agency.shippingCity,
            shippingState: agency.shippingState,
            shippingCountry: agency.shippingCountry,
            shippingLongitude: agency.shippingLongitude,
            shippingLatitude: agency.shippingLatitude,
            shippingGpsLocation: agency.shippingGpsLocation,
            shippingPhone: agency.shippingPhone,
            shippingPostalCode: agency.shippingPostalCode,
            date_time: agency.date_time,
            dateModified: agency.dateModified,
            syblUsers: agency.syblUsers,
            naicsCodes: agency.naicsCodes,
            rnCodes: agency.rnCodes,
            licensedStates: agency.licensedStates,
            topCarriers: agency.topCarriers, 
            personalLines: agency.personalLines,
            user: user
          });
        },
        updateAgencyInfoStore(agencyInfo) {

          patchState(state, {
            address1: agencyInfo.address1,
            address2: agencyInfo.address2,
            tenant: agencyInfo.tenant,
            earlyAdopter: agencyInfo.earlyAdopter,
            email: agencyInfo.email,
            city: agencyInfo.city,
            country: agencyInfo.country,
            gpsLocation: agencyInfo.gpsLocation,
            latitude: agencyInfo.latitude,
            longitude: agencyInfo.longitude,
            phone: agencyInfo.phone,
            postalCode: agencyInfo.postalCode,
            shippingAddress1: agencyInfo.shippingAddress1,
            shippingAddress2: agencyInfo.shippingAddress2,
            shippingCity: agencyInfo.shippingCity,
            shippingCountry: agencyInfo.shippingCountry,
            shippingGpsLocation: agencyInfo.shippingGpsLocation,
            shippingLatitude: agencyInfo.shippingLatitude,
            shippingLongitude: agencyInfo.shippingLongitude,
            shippingPhone: agencyInfo.shippingPhone,
            shippingPostalCode: agencyInfo.shippingPostalCode,
            shippingState: agencyInfo.shippingState,
            state: agencyInfo.state,
          });
        },

        updateAgencyUser(email, firstName, lastName) {
          console.log("updateAgencyUser", email, firstName, lastName)
          patchState(state, {
            user: {...state.user(), firstName:firstName, lastName:lastName }
          });
        },
        setAgencyDetailsAfterAddingUser(agency){
          console.log('setAgencyDetailsAfterAddingUser(agency)', agency);
          patchState(state, {
            agency_id: agency.agency_id,
            active_seats: agency.active_seats,
            address1: agency.address1,
            address2: agency.address2,
            agency_name: agency.agency_name,
            city: agency.city,
            country: agency.country,
            currency: agency.currency,
            dateModified: agency.dateModified,
            date_time: agency.date_time,
            email: agency.email,
            kill_bill_id: agency.kill_bill_id,
            latitude: agency.latitude,
            licenseType: agency.licenseType,
            licensedStates: agency.licensedStates,
            longitude: agency.longitude,
            naicsCodes: agency.naicsCodes,
            postalCode: agency.postalCode,
            purchased_seats: agency.purchased_seats,
            rnCodes: agency.rnCodes,
            state: agency.state,
            syblUsers: agency.syblUsers,
            tenant: agency.tenant,
            topCarriers: agency.topCarriers,
            uuid: agency.uuid,
            inactive_seats: agency.inactive_seats,
          })
        },
        updateAgencyFirmographicsStore(agencyInfo) {
          patchState(state, {
            employeeCount: agencyInfo.employeeCount,
            yearlyRevenue: agencyInfo.yearlyRevenue,
            licensedStates: agencyInfo.licensedStates,
            personalLines: agencyInfo.personalLines,
            naicsCodes: agencyInfo.naicsCodes,
            rnCodes: agencyInfo.rnCodes,
          });
        },
        setUsers(syblUsers){
          patchState(state, {purchased_seats:syblUsers.length, syblUsers:syblUsers})
        },
        async saveSyblAgencyFirmographics(agencyFirmographics, jwt) {
          console.log('saveSyblAgencyFirmographics--', agencyFirmographics)
          const agency = {
            agency_id: state.agency_id(),
            licensedStates: agencyFirmographics.licensedStates,
            naicsCodes: agencyFirmographics.naicsCodes,
            rnCodes: agencyFirmographics.rnCodes,
            personalLines: agencyFirmographics.personalLines,
            employeeCount: agencyFirmographics.employeeCount,
            yearlyRevenue: agencyFirmographics.yearlyRevenue,
          };
          return await this.saveSyblAgency(agency, jwt);
        },
        increaseUsers(){
          patchState(state, {purchased_seats: state.purchased_seats() + 1 })
        },
        async saveSyblAgencyInfo(form, jwt) {
          const agency = {
            agency_id: state.agency_id(),
            uuid: state.uuid(),
            address1: form.address1,
            address2: form.address2,
            city: form.city,
            country: form.country,
            state: form.state,
            phone: form.phone,
            postalCode: form.postalCode,
            shippingAddress1: form.shippingAddress1,
            shippingAddress2: form.shippingAddress2,
            shippingCity: form.shippingCity,
            shippingState: form.shippingState,
            shippingCountry: form.shippingCountry,
            shippingPhone: form.shippingPhone,
            shippingPostalCode: form.shippingPostalCode,
          };

          if (state.agency_id !== undefined) {
            return await this.saveSyblAgency(agency, jwt);
          } else return null;
        },

        async saveSyblAgency(item, jwt) {
          //   const jwt = authStatus.jwtToken();
          /** Move to somewhere else */
          return await syblAccountManagementHttpService.saveSyblAgency(
            item,
            jwt
          ); 
        },

        async saveSyblAgencyProfile(form, jwt) {
          const agency = {
            agency_id: state.agency_id(),
            uuid: state.uuid(),
            email: state.email,
            address1: form.address1,
            address2: form.address2,
            city: form.city,
            country: form.country,
            state: form.state,
            phone: form.phone,
            postalCode: form.postalCode,
            shippingAddress1: form.shippingAddress1,
            shippingAddress2: form.shippingAddress2,
            shippingCity: form.shippingCity,
            shippingState: form.shippingState,
            shippingCountry: form.shippingCountry,
            shippingPhone: form.shippingPhone,
            shippingPostalCode: form.shippingPostalCode,
          };
          console.log('save agency profile', form)
          if (state.agency_id !== undefined) {
            return await this.saveSyblAgency(agency, jwt);
          } else return null;
        },
        setLoading(){
          patchState(state, {loaded:false})
        },
        setLoaded(){
          patchState(state, {loaded:true})
        },

        searchFilterChange(form) {
          patchState(state, {
            filters: form.filters,
            searchType: form.searchType,
            searchMultipleProperties: form.searchMultipleProperties,
            totalCount: form.totalCount,
            pageIndex: form.pageIndex,
            moreToLoad: form.moreToLoad,
            loaded: form.loaded,
            sortBy: form.sortBy,
            sortDirection: form.sortDirection,
            displayCount: form.displayCount,
            pageSize: form.pageSize,
          });
        },
        logout() {
          patchState(state, {
            loaded: true,
            xApiKey: 'advisor@2024',
            agency_id: '',
            user: undefined,
            uuid: '',
            tenant: undefined,
            employeeCount: undefined,
            earlyAdopter: false,
            yearlyRevenue: undefined,
            agency_name: '',
            purchased_seats: 0,
            active_seats: 0,
            inactive_seats: 0,
            billingPeriod: undefined,
            productCategory: undefined,
            phaseType: undefined,
            subscriptionState: undefined,
            sourceType: undefined,
            currency: undefined,
            licenseType: undefined,
            address1: '',
            address2: '',
            city: '',
            country: '',
            state: '',
            longitude: undefined,
            latitude: undefined,
            gpsLocation: undefined,
            phone: '',
            postalCode: '',
            shippingAddress1: '',
            shippingAddress2: '',
            shippingCity: '',
            shippingState: '',
            shippingCountry: '',
            shippingLongitude: undefined,
            shippingLatitude: undefined,
            shippingGpsLocation: undefined,
            shippingPhone: '',
            shippingPostalCode: '',
            date_time: undefined,
            dateModified: undefined,
            syblUsers: [],
            naicsCodes: [],
            rnCodes: [],
            licensedStates: [],
            personalLines: false,
            topCarriers: [],
            encrypted: false,
            protected: false,
            filters: [
              { name: 'email', category: 'topProperties' },
              { name: 'agency_name', category: 'topProperties' },
              { name: 'lastName', category: 'topProperties' },
              { name: 'firstName', category: 'topProperties' },
              { name: 'permissions', category: 'topProperties' },
              { name: 'user_id', category: 'topProperties' },
            ],
            advancedSearchValues: {},
            sortDirection: 'desc',
            sortBy: 'date_time',
            searchOperator: '',
            searchMultipleProperties: '',
            totalCount: 0,
            pageSize: 50,
            pageIndex: 0,
            sort: false,
            moreToLoad: false,
            maxPageIndex: 0,
            lastId: '',
            displayCount: 0,
            searchType: '',
          });
        },
        filterUsers(){

        },
        searchOptionSelected(pagination) {  

          return syblAccountManagementHttpService.userFilterSearch(pagination, state.agency_id()).subscribe((res=>{

            if(res.pagination && res.results){
              patchState(state, {syblUsers:res.results})
            }            
          }))

          /*
          return userHttpService.userSelectedPropSearch(pagination).subscribe((res) => {

            if(res.pagination && res.results){
              patchState(state, {results:res.results})
            }
          });
          */
        },
      })
    )
  );
}
