/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { AppStatusState } from './app.reducers';


export function withAppShellMethods() {
  return signalStoreFeature(
    { state: type<AppStatusState>() },
    //todoService ad authService after ---> state, todoService = inject(TodoService))
    withMethods((state,) => ({
      closeLeftSidenav() {
        return patchState(state, { showLeftSidenav: false, hideToolbar:false });
      },
      closeRightSidenav() {
        return patchState(state, { showRightSidenav: false, hideToolbar:false  });
      },
      toggleLeftSidenav() {
        const previousState = state.showLeftSidenav();
        let hideToolbar = true;
        if(!previousState===false){
          hideToolbar = true;
        }
        return patchState(state, { showLeftSidenav: !previousState, hideToolbar:hideToolbar });
      },
      toggleRightSidenav() {
        const previousState = state.showRightSidenav();
        let hideToolbar = true;
 
        if(!previousState===false){
          hideToolbar = true;
        }
        return patchState(state, { showRightSidenav: !previousState, hideToolbar: hideToolbar });
      },
      hideToolbarMethod(value:any){
        return patchState(state, {hideToolbar:value})
      },
   
      setEnvironment(env_name:string, environment:any){
        // Set test for the url to set the environment.

        return patchState(state, {environment: environment, env_name:env_name })
      },
      setRoutePage(routePage:any){
        return patchState(state, { routePage: routePage})
      },
      openLeftSidenav() {
        return patchState(state, { showLeftSidenav: true });
      },
      openRightSidenav() {
        return patchState(state, { showLeftSidenav: true });
      },
      setAppDetailsOnLoad(appDetails: any) {
        return patchState(state, {
          smallerLogo: appDetails.SMALL_LOGO,
          largerLogo: appDetails.LARGE_LOGO,
        });
      },
      pageLoading() {
        return patchState(state, { loading: true });
      },
      pageLoaded() {
        return patchState(state, { loading: false });
      },
      /**  Look into these functions  */
      initialWindow() {
        if (typeof window !== "undefined"){
          const scrollPos = window.scrollY;
        }
        return; //this.getScrollPos$.next(scrollPos);
      },
      goToTop(): void {
        if (typeof window !== "undefined") {
          window.scrollTo(0, 0);
        }
        return; //this.document.documentElement.scrollTop;
      },

      getWindowInfo() {
        let mobileBrowser = false;
        if (typeof window !== 'undefined') {

        if (typeof window !== "undefined" && window.innerWidth < 800) {
          mobileBrowser = true;
        } else if(typeof window !== "undefined"){
          mobileBrowser = false;
        }else{
          console.log("Server No Window")
        }

        return patchState(state, {
          width: window.innerWidth,
          height: window.innerHeight,
          mobileBrowser: mobileBrowser,
        });
      }

      },
    }))
  );
}
