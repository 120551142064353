/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject, Inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';

import { SyblWebsocketStore } from './sybl-websocket';
import { IAuth } from '@sybl/feature-auth-models';
import { io } from 'socket.io-client';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import {
  IPaginatedSearch,
  PaginationParams,
  UUID,
} from '@sybl/common-models';
import {
  IWebSocketMessage,
  WebSocketMessage,
} from '@sybl/feature-websocket-models';
import {
  SyblAccountManagementHttpService,
  SyblAgencyStore,
  SyblStore,
} from '@sybl/feature-sybl-state';
import { IDocumentSaveError } from '@sybl/feature-documents-models';
import { InteractionsStore } from '@sybl/feature-interactions-state';
import { SyblMessage } from '@sybl/feature-sybl-models';
import { AppStatusStore } from '@sybl/core-state';

export function withSyblWebsocketMethods() {
  // inTimeout$: Observable<any> = this.store.select(WebsocketQuery.selectUserWebsocketInTimeout)
  let wsObservable: Observable<any>;
  const connected$ = new BehaviorSubject<boolean>(false);
  const debounceConnection$ = new BehaviorSubject<any>('');

  let webSocket!: any;

  return signalStoreFeature(
    { state: type<SyblWebsocketStore>() },
    withMethods(
      (
        state,
        syblStore: any = inject(SyblStore),
        interactionsStore: any = inject(InteractionsStore),
        syblHttpService: any = inject(SyblAccountManagementHttpService),
        syblAgencyStore: any = inject(SyblAgencyStore),
        appState: any = inject(AppStatusStore),
      ) => ({
        initializeWebSocket(authUser: IAuth): void {
          const appUrls = state.appUrls;

          let auth: any = {};
          //
          if (authUser && authUser.signUp === true) {
            const jwtToken = authUser.jwtToken;
            const email = authUser.email;
            const firstName = authUser.firstName;
            const lastName = authUser.lastName;
            const sessionId = authUser.sessionId;
            const user_id = authUser.user_id;
            const username = authUser.username;
            const permissions = authUser.permissions;

            auth = {
              signUp: true,
              jwtToken: jwtToken,
              email: email,
              firstName: firstName,
              lastName: lastName,
              sessionId: sessionId,
              user_id: user_id,
              username: username,
              permissons: permissions,
            };
          } else {
            auth = authUser;
          }

          try {
            const webSocketUrl = appUrls.API_WEB_SOCKET_ADDRESS(); // ?params=' + jsonWebToken;

            webSocket = io(webSocketUrl, {
              withCredentials: true,
              reconnectionDelayMax: 10000,
              auth: {
                token: auth,
              },
            });

            webSocket.io.on('open', () => {
              debounceConnection$.next(true);
              patchState(state, { connected: true });
              webSocket.io.engine.transport.on('pollComplete', () => {
                return;
              });
            });

            webSocket.io.on('close', (e) => {
              patchState(state, { connected: false });

              if (e.wasClean) {
                connected$.next(false);
              } else {
                connected$.next(false);
              }
            });

            webSocket.on('error', (e) => {
              const params = {
                uuid: new UUID().UUID(),
                payload: { error: e },
                status: 11,
                connected: false,
                msg: 'WEB_SOCKET_CONNECTION_ERROR',
                webSocketUrl: 'err',
              };

              const webSocketMessage = new WebSocketMessage(params);
            });

            webSocket.on('message', (message) => {
              //const stringyData = event.data;
              this.dataRouter(message, authUser, webSocket);
            });
          } catch (err) {
            console.log('err users websocket', err);
          }
        },
        getChatsForUser(authUser, pagination) {
          const searchForm: IPaginatedSearch = {
            paginationParams: pagination,
          };
          // Only send if not found, try to check time last got as well.
          const message = new WebSocketMessage({
            user_id: authUser.user_id,
            webSocketUrl: 'sybl/chats-paginated',
            uuid: new UUID().UUID(),
            msg: 'GET_CHATS_FOR_USER',
            payload: searchForm,
            jwtToken: authUser.jwtToken,
            status: 1,
          });

          webSocket.send(message);
        },

        async getAgencyForUser(user_id) {

          if (user_id) {
            // const request$ = this.httpClient.get(yourUrl)

            try {
              const request$ = syblHttpService.getSyblAgencyForUser(user_id);
              const agencyForUser = await lastValueFrom<any>(request$);

              if (agencyForUser.agency) {
                syblAgencyStore.setAgency(
                  agencyForUser.agency,
                  agencyForUser.agencyUser,
                );
              }
            } catch (err) {
              console.log('err doing this', err);
            }
          }
        },
        getChat(authUser, chat_id) {
          const user_id = authUser.user_id;
          const jwtToken = authUser.jwtToken;

          const oldChats = syblStore.chats();
          // Really should set using default variables here
          const newChats = { ...oldChats, [chat_id]: { pageIndex: 0 } };
          //  patchState(syblStore, { chats: newChats });

          if (syblStore.chats()[chat_id] === undefined) {
            // Only send if not found, try to check time last got as well.
            const message = new WebSocketMessage({
              user_id: user_id,
              webSocketUrl: 'sybl/chat-by-id',
              uuid: new UUID().UUID(),
              msg: 'GET_CHATS_FOR_USER',
              payload: chat_id,
              jwtToken: jwtToken,
              status: 1,
            });
            this.sendMessage(message);
          } else {
            // If chat alreeady exists set to active
            return syblStore.setAllreadyLoadedChat(chat_id);
          }
        },
        getMessagesForChat(authUser, chat_id, messagesTotal?) {
          // Check if pagination exists in store if it does use and update these values on pagination interactions

          const chatInfo = syblStore.chats()[chat_id];

          if (chatInfo === undefined) {
            const params = {
              fieldOne: chat_id,
              pageIndex: 0,
              pageSize: 30, // Could set these to defaults in store...
              sortDirection: 'desc',
              sortBy: 'date_time',
            };

            const pagination = new PaginationParams(params);

            const message = new WebSocketMessage({
              user_id: authUser.user_id,
              webSocketUrl: 'sybl/messages-for-chat',
              uuid: new UUID().UUID(),
              msg: 'GET_MESSAGES_FOR_CHAT',
              payload: pagination,
              jwtToken: authUser.jwtToken,
              status: 1,
            });

            this.sendMessage(message);
          } else {
            // See if chatInfo has pagination, if it does make changes if it does not add info.

            let newIndex = 1;

            if (chatInfo.pageIndex !== undefined) {
              newIndex = chatInfo.pageIndex + 1;
            }

            if (chatInfo.pageFromDB === newIndex - 1) {
              const updateChat = { ...chatInfo, pageIndex: newIndex };

              patchState(syblStore, { chats: { [chat_id]: updateChat } });

              const params = {
                fieldOne: chat_id,
                pageIndex: newIndex,
                pageSize: 30, // Could set these to defaults in store...
                sortDirection: 'desc',
                sortBy: 'date_time',
              };

              const pagination = new PaginationParams(params);

              const message = new WebSocketMessage({
                user_id: authUser.user_id,
                webSocketUrl: 'sybl/messages-for-chat',
                uuid: new UUID().UUID(),
                msg: 'GET_MESSAGES_FOR_CHAT',
                payload: pagination,
                jwtToken: authUser.jwtToken,
                status: 1,
              });

              this.sendMessage(message);
            }
          }
          // Only send if not found, try to check time last got as well.
        },
        editChatTitle(authUser, chatInfo) {
          const chatMessageParams = {
            title: chatInfo.title,
            chat_id: chatInfo.chat_id,
          };

          const url = 'sybl/edit-chat-title';

          const websocketMessage = new WebSocketMessage({
            user_id: authUser.user_id,
            jwtToken: authUser.jwtToken,
            payload: chatMessageParams,
            webSocketUrl: url,
            uuid: new UUID().UUID(),
          });
          this.sendMessage(websocketMessage);
        },

        deleteChat(authUser, chat_id) {
          const url = 'sybl/delete-chat';

          const websocketMessage = new WebSocketMessage({
            user_id: authUser.user_id,
            jwtToken: authUser.jwtToken,
            payload: { chat_id: chat_id },
            webSocketUrl: url,
            uuid: new UUID().UUID(),
          });
          this.sendMessage(websocketMessage);

          syblStore.deleteChat(chat_id);
        },
        setChatTitle(authUser, message) {
          console.log('set Chat title How Many?')
          const msg_id = new UUID().UUID();
          const msg_text = message.msg_text;
          const context_filter = message.context_filter;

          const chatMessageParams = {
            msg_text: msg_text,
            context_filter: context_filter,
            date_time: new Date(),
            uuid: msg_id,
            msg_type: 'set_chat_title',
            msg_id: msg_id,
            msg_from: 'user_input',
            chat_id: message.chat_id,
            env_name: appState.env_name(),
            'x-api-key': syblStore.xApiKey(),
            user_id: authUser.user_id,
          };

          const newChatMessage = new SyblMessage(chatMessageParams);

          const url = 'sybl/set-chat-title';

          const websocketMessage = new WebSocketMessage({
            user_id: authUser.user_id,
            jwtToken: authUser.jwtToken,
            payload: newChatMessage,
            webSocketUrl: url,
            uuid: new UUID().UUID(),
          });
          this.sendMessage(websocketMessage);
        },
        editMessageForChat(authUser, message) {
          const msg_text = message.msg_text;
          const context_filter = message.context_filter;

          const chatMessageParams = {
            msg_text: msg_text,
            context_filter: context_filter,
            date_time: new Date(),
            uuid: message.msg_id,
            msg_type: 'user_input',
            msg_id: message.msg_id,
            msg_from: 'user_input',
            chat_id: message.chat_id,
            env_name: appState.env_name(),
            'x-api-key': syblStore.xApiKey(),
            user_id: authUser.user_id,
            editMessage: message.editMessage,
          };

          const newChatMessage = new SyblMessage(chatMessageParams);

          const url = 'sybl/edit-message';

          const websocketMessage = new WebSocketMessage({
            user_id: authUser.user_id,
            jwtToken: authUser.jwtToken,
            payload: newChatMessage,
            webSocketUrl: url,
            uuid: new UUID().UUID(),
          });
          this.sendMessage(websocketMessage);
        },
        getInteractionForUser(auth_user, msg_id) {
          const user_id = auth_user.user_id;
          const jwtToken = auth_user.jwtToken;
          if (user_id !== null && user_id !== '') {
            // Only send if not found, try to check time last got as well.
            const message = new WebSocketMessage({
              user_id: user_id,
              webSocketUrl: 'sybl/get-interaction-for-user',
              uuid: new UUID().UUID(),
              msg: 'GET_INTERACTION_FOR_USER',
              payload: { msg_id: msg_id, user_id: user_id },
              jwtToken: jwtToken,
              status: 1,
            });
            webSocket.send(message);
          }
        },
        sendInteraction(authUser, interaction) {
          const user_id = authUser.user_id;
          const jwtToken = authUser.jwtToken;

          // Only send if not found, try to check time last got as well.
          const message = new WebSocketMessage({
            user_id: user_id,
            webSocketUrl: 'sybl/post-interaction',
            uuid: new UUID().UUID(),
            msg: 'SEND_INTERACTION',
            payload: interaction,
            jwtToken: jwtToken,
            status: 1,
          });
          webSocket.send(message);
        },
        sendInteractionComment(authUser, comment, msg_id) {
          const user_id = authUser.user_id;
          const jwtToken = authUser.jwtToken;

          // Only send if not found, try to check time last got as well.
          const message = new WebSocketMessage({
            user_id: user_id,
            webSocketUrl: 'sybl/post-interaction-comment',
            uuid: new UUID().UUID(),
            msg: 'SEND_INTERACTION_COMMENT',

            payload: { text: comment, msg_id: msg_id },
            jwtToken: jwtToken,
            status: 1,
          });
          webSocket.send(message);
        },
        sendSyblMessageError(authUser, errorMessage) {
          const user_id = authUser.user_id;
          const jwtToken = authUser.jwtToken;

          // Only send if not found, try to check time last got as well.
          const message = new WebSocketMessage({
            user_id: user_id,
            webSocketUrl: 'post-sybl-message-error',
            uuid: new UUID().UUID(),
            msg: 'SEND_INTERACTION_COMMENT',

            payload: errorMessage,
            jwtToken: jwtToken,
            status: 1,
          });
          webSocket.send(message);
        },
        sendMessage(webSocketMessage: IWebSocketMessage) {
          if (webSocket) {
            syblStore.setLoading();

            webSocket.send(webSocketMessage);
          }
        },

        logout() {
          if (webSocket) {
            console.log('close webSocket on logout');

            syblStore.logout();
       
            
            webSocket.close();
          }
        },
        dataRouter(message: IWebSocketMessage, authUser, webSocket) {
          switch (message.webSocketUrl) {
            case 'auth/auth_attempt': {
              // WebSocket Must first be connected to and then auth details sent and profile received.
              if (message.msg === 'WEB_SOCKET_CONNECTION_SUCCESS') {
                patchState(state, { connected: true });

                const jwtToken = authUser.jwtToken;
                const message = new WebSocketMessage({
                  webSocketUrl: 'sybl/welcome',
                  uuid: new UUID().UUID(),
                  msg: 'GET_SYBL_WELCOME_ATTEMPT',
                  payload: authUser,
                  jwtToken: jwtToken,
                  status: 1,
                });
                syblStore.setLoaded();

                return webSocket.send(message);
              } else if (message.msg === 'WEB_SOCKET_CONNECTION_ERROR') {
                const payload = message.payload;
                const error = payload.error;

                //  return this.userLoginFacade.loggedInUserAuthFail(error)
              }
              break;
            }
            case 'auth/jwt-expired': {
              console.log('Do Something with expired token');
              break;
            }

            case 'sybl/welcome': {
              syblStore.setLoaded();

              if (message.msg === 'SUCCESS') {
                return syblStore.setWelcomeMessage(message);
              } else if (message.msg === 'ERROR') {
                const errorPayload: IDocumentSaveError = message.payload;
                const master_id = errorPayload.master_id;
                const status = errorPayload.status;
                return {}; //this.documentsFacade.updateDocumentStatus(master_id, status)
              }

              break;
            }
            case 'sybl/chat-by-id': {
              const chat_id = message.payload.chat_id;
              if (chat_id !== null) {
                const totalMessages = message.payload.totalMessages;

                this.getMessagesForChat(authUser, chat_id, totalMessages);
              }

              return syblStore.setChat(message.payload);
            }
            case 'sybl/receive-message': {
              syblStore.setLoaded();
              if (message.msg === 'FAIL') {
                console.error('Message Failed', message);

                /*   chat_id: syblMessage.chat_id,
                    user_id: user_id,
                    date_time: new Date(),
                    prompt_id:  syblMessage.msg_id,
                    msg_id: new UUID().UUID(),
                    context_filter: syblMessage.context_filter,
                    msg_from: 'api_error', // Will have to only return not api_error on lookups...
                    msg_text: err.code,
                    msg_type: 'error',
                */
                // Retry Sending Will want to put in a limit on the number of retries...
                // Really should spend time and make a retry service, keep track of in state. For everything.

                return syblStore.receivedMessage(message);
              } else if (message.msg === 'SUCCESS') {
                return syblStore.receivedMessage(message);
              }
              // Check for successfail here
              return;
            }
            case 'sybl/messages-for-chat': {
              syblStore.setLoaded();

              return syblStore.receivedMessagesForChat(message.payload);
            }
            case 'sybl/edit-chat-title':
            case 'sybl/set-chat-title': {
              return syblStore.setChatTitle(message.payload);
            }

            case 'sybl/chats-paginated': {
              return syblStore.receivedChatHistory(message.payload);
            }
            case 'sybl/get-interaction-for-user': {
              return interactionsStore.receivedFromServer(message.payload);
            }
            case 'sybl/post-interaction-comment': {
              return interactionsStore.receivedFromServer(message.payload);
            }
            case 'sybl/typeAhead': {
              return {}; //this.userProfileFacade.searchResults(message.payload)
            }

            default:
              break;
          }
          return {};
        },
     
      }),
    ),
  );
}
