/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { BehaviorSubject, pipe, switchMap, tap } from 'rxjs';
import {
  AuthBackendService,
  AuthCookieService,
  BrowserInfoService,
} from '@sybl/feature-auth-data-access';
import { SyblStoreState } from './sybl-store';
import { ISessionResponse } from '@sybl/feature-auth-models';
import {
  IWebSocketMessage,
  WebSocketMessage,
} from '@sybl/feature-websocket-models';
import {
  DocumentStatusEnum,
  IDocumentSaveError,
} from '@sybl/feature-documents-models';
import { UUID } from '@sybl/common-models';
import { ISyblChat, SyblChat } from '@sybl/feature-sybl-models';

export function withSyblStoreMethods() {
  return signalStoreFeature(
    { state: type<SyblStoreState>() },
    withMethods((state) => ({
      setWelcomeMessage(webSocketMessage) {
        const welcome = webSocketMessage.payload;

        patchState(state, { welcome: welcome });
      },
      selectContext(chat_id, context_filter) {
        const chats = state.chats();
        const currentChat = chats[chat_id];

        if (currentChat) {
          const updateEntity = {
            ...currentChat,
            context_filter: context_filter,
          };
          const newEntities = {
            ...chats,
            [chat_id]: updateEntity,
          };

          return patchState(state, { chats: newEntities });
        }
      },
      setChat(chat) {
        const newIds = Array.from(new Set([...state.ids(), chat.chat_id]));

        const newEntities = {
          ...state.chats(),
          [chat.chat_id]: chat,
        };

        patchState(state, {
          selectedChatId: chat.chat_id,
          ids: newIds,
          chats: newEntities,
        });

        return;
      },
      /* getRecentChatInfo(chat_id:string){
        const entities = state.chats();
        console.log('chat_id', chat_id)
        console.log('entities', entities)

        const chat = entities[chat_id];

        return {chat_id:chat.chat_id, title: chat.title, date_time: chat.date_time }
      },
      */
      setAllreadyLoadedChat(chat_id) {
        patchState(state, {
          selectedChatId: chat_id,
        });

        return;
      },
      newChat(user_id) {

        const uuid = new UUID().UUID();
        const newChat = new SyblChat({
          chat_id: uuid,
          user_id: user_id,
          messages: [],
          date_time: new Date(),
        });
        const selectedChatId = newChat.chat_id;
        const chats = state.chats();

        const newIds = Array.from(new Set([...state.ids(), newChat.chat_id]));
        const oldChattResults = state.recentChatResults();

        const newChatResults = [newChat, ...oldChattResults]; // append to front, update naming convention

        const newEntities = {
          ...chats,
          [newChat.chat_id]: newChat,
        };

        //, chats: newEntities
        patchState(state, {
          selectedChatId: selectedChatId,
          ids: newIds,
          chats: newEntities,
          recentChatResults: newChatResults,
        });
        return;
      },
      setLoaded() {
        patchState(state, { loaded: true });
      },
      setLoading() {
        patchState(state, { loaded: false });
      },
      logout() {
        patchState(state, {
          loaded: true,
          chats: {},
          xApiKey: 'advisor@2024',
          selectedChatId: undefined,
          ids: [],
          welcome: {
            msg_text: 'Please Login to use SYBL Ai powered chat',
            contexts: [],
            uuid: undefined,
            basket_id: undefined,
          },
          recentChatSearch: {
            sortDirection: 'desc',
            sortBy: 'date_time',
            variableType: 'date',
            searchOperator: '',
            searchMultipleProperties: '',
            totalCount: 0,
            pageSize: 30,
            pageIndex: 0,
            sort: true,
            moreToLoad: false,
            maxPageIndex: 0,
            lastId: '',
          },

          recentChatResults: [],
        });
      },
      // Send Message to store, before sending to server
      saveMessageToState(chatMessage) {
        const chat_id = chatMessage.chat_id;
        const oldChats = state.chats();
        try {
          const findChat = state.chats()[chat_id];
          const messages = findChat.messages;
          const updateMessages = [...messages, chatMessage];
          const updateChat = {
            ...findChat,
            messages: updateMessages,
            lastUserMessage: chatMessage.msg_id,
          };
          const newChats = { ...oldChats, [chat_id]: updateChat };
          patchState(state, { chats: newChats });
        } catch (err) {
          // Return the message to send to server.
          console.log('err finding chat', err);
        }
        return;
      },

      receivedMessage(webSocketMessage: WebSocketMessage) {
        const chatMessage = webSocketMessage.payload;
        // Check if msg_type = get_chat_title if it is set it on the chat.
        // console.log("Received Message SYBL", chatMessage)

        const oldChats = state.chats();
        const chat_id = chatMessage.chat_id;
        const findChat = state.chats()[chat_id];
        const messages = findChat.messages;
        const updateMessages = [...messages, chatMessage];
        const updateChat = { ...findChat, messages: updateMessages };
        const newChats = { ...oldChats, [chat_id]: updateChat };

        patchState(state, { chats: newChats, loaded:true });
      },

      receivedMessagesForChat(payload: WebSocketMessage['payload']) {
        console.log("Received Message for chat", payload)
        const chat_id = payload.chat_id;
        const results = payload.results;
        const pagination = payload.pagination;

        let lastUserMessage = undefined;

        const oldChats = state.chats();
        const findChat = state.chats()[chat_id];
        const oldMessages = findChat.messages;

        const newMessages = [
          ...oldMessages.filter(
            (message) => message.msg_from !== undefined
          ),
          ...results,
        ].sort(compareByDate);

        function compareByDate(a, b) {
          return (
            new Date(a.date_time).getTime() -
            new Date(b.date_time).getTime()
          );
        }

        console.warn("THIS MAY BE AN ISSUE HERE")

        if (newMessages.length === findChat.totalMessages) {
          const filterUserMessages = newMessages.filter(
            (entry) => entry.msg_from === 'msg_text'
          );
          const filterUserMsgLength = filterUserMessages.length;

          if (filterUserMsgLength >= 1) {
            lastUserMessage =
              filterUserMessages[filterUserMsgLength - 1].msg_id;
          }
        }
        // Check to see if total number of messages = messages loaded.

        // If it is try to find last user Message; Need to update it

        const updateChat = {
          ...findChat,
          messages: newMessages,
          lastUserMessage: lastUserMessage,
          pageIndex: pagination.pageIndex,
          pageFromDB: pagination.pageIndex,
        };
        const newChats = { ...oldChats, [chat_id]: updateChat };

        patchState(state, { chats: newChats, loaded:true });
      },

      deleteChat(chat_id){
        try {
          const recentChats = state.recentChatResults();
          const findRecentChat = recentChats.findIndex(
            (entry) => entry.chat_id === chat_id
          );
          const newRecentChats = recentChats.filter((entry, index)=>index !== findRecentChat)
          patchState(state, {
            recentChatResults: newRecentChats,
          });

        }catch(err){
          console.log('err', err)
        }
        return
      },

      editMessage(message) {
        const findChat = state.chats()[message.chat_id];

        if (findChat) {
          const oldChats = state.chats();
          const resultsIndex = findChat.messages.findIndex(
            (entry) => entry.msg_id === message.editMessage
          );
          const newMessages = findChat.messages.filter((entry, index) => {
            return index < resultsIndex;
          });

          newMessages.push(message);

          const updateChat = {
            ...findChat,
            messages: newMessages,
            lastUserMessage: message.msg_id,
          };
          const newChats = { ...oldChats, [message.chat_id]: updateChat };
          return patchState(state, { chats: newChats });
        } else return;
      },

      receivedChatHistory(pagination: any) {
        const oldChatResults = state.recentChatResults();
        const newResults = pagination.results.filter(
          (newEntry) => !oldChatResults.some(oldEntry => oldEntry.chat_id === newEntry.chat_id)
        )

        const newChatPagination = {
          lastId: pagination.paginationParams.lastId,
          maxPageIndex: pagination.paginationParams.maxPageIndex,
          moreToLoad: pagination.paginationParams.moreToLoad,
          pageIndex: pagination.paginationParams.pageIndex,
          pageSize: pagination.paginationParams.pageSize,
          searchMultipleProperties:
          pagination.paginationParams.searchMultipleProperties,
          searchOperator: pagination.paginationParams.searchOperator,
          sort: pagination.paginationParams.sort,
          sortBy: pagination.paginationParams.sortBy,
          sortDirection: pagination.paginationParams.sortDirection,
          totalCount: pagination.paginationParams.totalCount,
          variableType: pagination.paginationParams.variableType,
        };

        patchState(state, {
          recentChatResults: [...oldChatResults, ...newResults],
          recentChatSearch: newChatPagination,
        });
      },

      getSelectedChat() {
        if (state.chats && state.selectedChatId) {
          return state.chats()[state.selectedChatId()];
        }
        return undefined;
      },
      getSelectedChatTitle() {
        if (state.chats && state.selectedChatId) {
          return state.chats()[state.selectedChatId()].title;
        }
        return undefined;
      },
      getSelectedChatLoadedMessages() {
        if (state.chats && state.selectedChatId) {
          return state.chats()[state.selectedChatId()].messages.length;
        }
        return undefined;
      },
      lastUserMessage() {
        if (state.chats && state.selectedChatId) {
          return state.chats()[state.selectedChatId()].lastUserMessage;
        }
        return undefined;
      },

      setChatTitle(chatInfo: { chat_id: string; title: string }) {
        const oldChats = state.chats();
        const chat_id = chatInfo.chat_id;
        console.log('Set CHat title for message', chatInfo)

        try {
          const findChat = state.chats()[chatInfo.chat_id];
          const recentChats = state.recentChatResults();

          const findRecentChat = recentChats.findIndex(
            (entry) => entry.chat_id === chat_id
          );

          recentChats[findRecentChat].title = chatInfo.title;

          const updateChat = { ...findChat, title: chatInfo.title };

          const newChats = { ...oldChats, [chat_id]: updateChat };
          // fi
          patchState(state, {
            chats: newChats,
            recentChatResults: recentChats,
          });
        } catch (err) {
          // Return the message to send to server.
          console.log('err finding chat', err);
        }
      },
      todayChatResults() {
        const currentDate = new Date();
        const maxDate = new Date().setDate(currentDate.getDate() - 1);
        // Add ten days to specified date

        const filterResults = state.recentChatResults().filter((entry) => {
          return new Date(entry.date_time).getTime() >= maxDate;
        }).sort(compareByDate);

        function compareByDate(b, a) {
          return (
            new Date(a.date_time).getTime() -
            new Date(b.date_time).getTime()
          );
        }

        return filterResults;
      },
      yesterdayChatResults() {
        const currentDate = new Date();
        const minDate = new Date().setDate(currentDate.getDate() - 1);
        const maxDate = new Date().setDate(currentDate.getDate() - 2.999);

        // Add ten days to specified date
        return state
          .recentChatResults()
          .filter(
            (entry) =>
              new Date(entry.date_time).getTime() <= minDate &&
              new Date(entry.date_time).getTime() >= maxDate
          );
      },
      prevSevenChatResults() {
        const currentDate = new Date();
        const minDate = new Date().setDate(currentDate.getDate() - 3);
        const maxDate = new Date().setDate(currentDate.getDate() - 7.999999);

        // Add ten days to specified date
        return state
          .recentChatResults()
          .filter(
            (entry) =>
              new Date(entry.date_time).getTime() <= minDate &&
              new Date(entry.date_time).getTime() >= maxDate
          );
      },
      prevThirtyChatResults() {
        const currentDate = new Date();
        const minDate = new Date().setDate(currentDate.getDate() - 8);
        const maxDate = new Date().setDate(currentDate.getDate() - 30);

        // Add ten days to specified date
        return state
          .recentChatResults()
          .filter(
            (entry) =>
              new Date(entry.date_time).getTime() <= minDate &&
              new Date(entry.date_time).getTime() >= maxDate
          );
      },

      typeAhead(msg_text: string, user_id, jwtToken) {
        const message = new WebSocketMessage({
          user_id: user_id,
          webSocketUrl: 'profiles/typeAhead',
          uuid: new UUID().UUID(),
          msg: 'ATTEMPT',
          payload: { msg_text: msg_text },
          jwtToken: jwtToken,
          status: 1,
        });

        return; // websocketService.sendUserWebsocketMessage(message)
      },

      async hoot(email: string, password: string) {
        // Placeholder
        //patchState(state, { loading: true })
      },
    }))
  );
}
